import { useQuery } from '@apollo/client';
import { Outlet } from 'react-router-dom';
import vitalitylogo from '../../assets/vitality-logo-ellipse.svg';
import { adviserHost } from '../../constants/application-constants';
import ReactErrorBoundary from '../../global/error/ReactErrorBoundary';
import { Footer } from '../Footer/FooterNavigation';
import { NavLink } from './SideNavigationLinks';
import { FooterInformationBarQuery } from './footer-information-bar.gql';
import { SideNavigationQuery } from './side-navigation-query.gql';

export const SideNavigation = () => {
  const { data } = useQuery(SideNavigationQuery);
  const footerInformationBarResponse = useQuery(FooterInformationBarQuery);

  const userType = localStorage.getItem('userType')?.toString();
  const isAdviser = userType?.toLocaleLowerCase() === adviserHost.toLocaleLowerCase();

  let footerInformation;
  if (footerInformationBarResponse?.data) {
    const { item } = footerInformationBarResponse.data;
    footerInformation = item;
  }
  let SideNavigationData;
  let FooterNavigationDetails;
  let WebsiteHeader;
  if (data && Object.keys(data).length != 0) {
    const { item } = data;
    const { employerWebsiteTitle } = item;
    const { adviserWebsiteTitle } = item;
    const { navigationJson } = isAdviser?(item?.adviserNavigation || undefined):
    (item?.navigation || undefined);
    SideNavigationData = navigationJson;
    FooterNavigationDetails = item;
    WebsiteHeader = isAdviser? adviserWebsiteTitle:employerWebsiteTitle;
  }
  const clearStorage = () => {
    const returnPathKey = 'returnTo.path';
    const returnSearchKey = 'returnTo.search';
    sessionStorage.removeItem(returnPathKey);
    sessionStorage.removeItem(returnSearchKey);
    localStorage.clear();
  };

  const displayFooterBar = () => {
    const footerBarFields = isAdviser
      ? ['AdviserFooterSection1', 'AdviserFooterLink', 'AdviserFooterSection2']
      : ['FooterSection1', 'FooterLink', 'FooterSection2'];
    return (
      <p>
        {footerInformation?.[footerBarFields[0]]?.value}{' '}
        <a href={footerInformation?.[footerBarFields[1]]?.value} onClick={clearStorage} target="_self" style={{ color: '#f41c5e' }}>
          Click
        </a>{' '}
        {footerInformation?.[footerBarFields[2]]?.value}
      </p>
    );
  };

  const displayFooterHelp = () => {
    const footerHelpFields = isAdviser ? ['AdviserFooterHelpTextLink', 'AdviserFooterHelpText'] : ['FooterHelpTextLink', 'FooterHelpText'];
    return (
      <button
        className="vds-information-bar__help-trigger"
        onClick={() => {
          window.open(footerInformation?.[footerHelpFields[0]]?.jsonValue.value.href, '_blank');
        }}>
        <i className="fa-solid fa-question"></i>
        {footerInformation?.[footerHelpFields[1]]?.value}
      </button>
    );
  };
  return (
    <div className="vds-dashboard">
      <div className="vds-dashboard__inner">
        <aside className="vds-dashboard__navigation">
          <nav className="vds-primary-navigation-side">
            <div className="vds-primary-navigation-side__inner">
              <div className="vds-primary-navigation-side__top-bar">
                <header className="vds-primary-navigation-side__header">
                  <a className="vds-primary-navigation-side__header-link" href="#">
                    <img className="vds-primary-navigation-side__logo" src={vitalitylogo} height="48" width="48" alt="Vitality logo" />
                    {WebsiteHeader?.value}
                  </a>

                  <button className="vds-primary-navigation-side__menu-toggle" type="button" title="Open menu" data-panel-target="#primary-nav">
                    <i className="fa-solid fa-bars"></i>
                  </button>
                </header>
              </div>

              <aside
                id="primary-nav"
                className="vds-primary-navigation-side__main vds-off-canvas vds-off-canvas--slide-out vds-off-canvas--closed"
                aria-live="polite"
                aria-atomic="true">
                <header className="vds-primary-navigation-side__header">
                  <a className="vds-primary-navigation-side__header-link" href="#">
                    <img className="vds-primary-navigation-side__logo" src={vitalitylogo} height="48" width="48" alt="Vitality logo" />
                    {WebsiteHeader?.value}
                  </a>

                  <button className="vds-primary-navigation-side__menu-toggle" type="button" title="Close menu" data-panel-target="#primary-nav">
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </header>

                <div className="vds-primary-navigation-side__main-inner">
                  <ul className="vds-primary-navigation-side__list">
                    {data && Object.keys(data).length != 0 && (
                      <>
                        {SideNavigationData.map((item, index) => {
                          return (
                            <NavLink
                              id={index}
                              title={item.fields.Title}
                              path={item.fields.Path}
                              icon={item.fields.Icon}
                              key={index}
                              isNew={item.fields.IsNew}
                              isComingSoon={item.fields.IsComingSoon}
                            />
                          );
                        })}
                      </>
                    )}
                  </ul>
                  <Footer data={[FooterNavigationDetails,isAdviser]} />
                </div>
              </aside>
            </div>
          </nav>
        </aside>
        <main className="vds-dashboard__content">
          <div className="vds-dashboard__content-inner">
            <ReactErrorBoundary>
              <Outlet />
            </ReactErrorBoundary>
          </div>
          <footer className="vds-information-bar vds-information-bar--bottom">
            <div className="vds-information-bar__inner">
              <div className="vds-information-bar__section">
                {/* <p dangerouslySetInnerHTML={{ __html: footerInformation }} style={{ display: 'none' }} /> */}
                {displayFooterBar()}
              </div>
              <div className="vds-information-bar__section-end">{displayFooterHelp()}</div>
            </div>
          </footer>
        </main>
      </div>
    </div>
  );
};
