import { ConfigProperties } from './config';

export const PrdConfig: ConfigProperties = {
  SITECORE_API_KEY: '{8BDE6ECE-4E18-4807-A807-CE328E998EF5}',
  GRAPHQL_ENDPOINT: 'https://cd.wc.vitality.co.uk/sitecore/api/graph/edge',
  V4B_CDN_HOST: 'https://prod-v4b.cdn.vitality.co.uk',
  WSO2_SSO_HOST: 'https://apis.vitality.co.uk/oauth2/oidcdiscovery',
  WSO2_CLIENT_ID: 'PxeXZwJF_fMNXo6YaPyvVqHy2zIa',
  EMPLOYER_HUB: 'https://employerhub.vitality.co.uk',
  SESSION_CHECKING_IS_OPTIONAL: true,
  WSO2_CLIENT_SECRET: '',
  ADVISER_CLIENT_ID: 'FMpg8TXNd2BZtbTd7Vv9ZJ5Grjca',
  ADVISER_CLIENT_SECRET: '',
};
