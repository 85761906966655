import { adviserHost, employerHost } from '../constants/application-constants';
import { LocalConfig } from './local';
import { PrdConfig } from './prd';
import { PreConfig } from './pre';
import { SitConfig } from './sit';
import { UatConfig } from './uat';

const host = window.location.host;

export interface ConfigProperties {
  SITECORE_API_KEY: string;
  GRAPHQL_ENDPOINT: string;
  V4B_CDN_HOST: string;
  WSO2_SSO_HOST: string;
  WSO2_CLIENT_ID: string;
  WSO2_CLIENT_SECRET: string;
  EMPLOYER_HUB: string;
  SESSION_CHECKING_IS_OPTIONAL: boolean;
  ADVISER_CLIENT_ID: string;
  ADVISER_CLIENT_SECRET: string;
}

let props: ConfigProperties;
props = LocalConfig;
if (/\bv4b\b/.test(host) || /\bemployers\b/.test(host)) {
  localStorage.setItem('userType',employerHost);
  if (/^prod-v4b.cdn.vitality.co.uk$/.exec(host) || /^employers.vitality.co.uk$/.exec(host)) {
    props = PrdConfig;
  } else if (/\bpre\b/.exec(host) || /^pre.employers.vitality.co.uk$/.exec(host)) {
    props = PreConfig;
  } else if (/\buat\b/.exec(host) || /^uat.employers.vitality.co.uk$/.exec(host)) {
    props = UatConfig;
  } else if (/\bsit\b/.exec(host) || /^sit.employers.vitality.co.uk$/.exec(host)) {
    props = SitConfig;
  } else {
    props = LocalConfig;
  }
}

if (/\badvisers\b/.test(host)) {  
  localStorage.setItem('userType',adviserHost);
  if (/^advisers.vitality.co.uk$/.exec(host)) {
    props = PrdConfig;
    props.WSO2_CLIENT_ID = PrdConfig.ADVISER_CLIENT_ID;
    props.WSO2_CLIENT_SECRET = PrdConfig.ADVISER_CLIENT_SECRET;
  } else if (/^pre.advisers.vitality.co.uk$/.exec(host)) {
    props = PreConfig;
    props.WSO2_CLIENT_ID = PreConfig.ADVISER_CLIENT_ID;
    props.WSO2_CLIENT_SECRET = PreConfig.ADVISER_CLIENT_SECRET;
  } else if (/^uat.advisers.vitality.co.uk$/.exec(host)) {
    props = UatConfig;
    props.WSO2_CLIENT_ID = UatConfig.ADVISER_CLIENT_ID;
    props.WSO2_CLIENT_SECRET = UatConfig.ADVISER_CLIENT_SECRET;
  } else if (/^sit.advisers.vitality.co.uk$/.exec(host)) {
    props = SitConfig;
    props.WSO2_CLIENT_ID = SitConfig.ADVISER_CLIENT_ID;
    props.WSO2_CLIENT_SECRET = SitConfig.ADVISER_CLIENT_SECRET;
  } else {
     
    props.ADVISER_CLIENT_ID = LocalConfig.ADVISER_CLIENT_ID;
    props.WSO2_CLIENT_SECRET = LocalConfig.ADVISER_CLIENT_SECRET;
  }
}

export const config: ConfigProperties = props;
