import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RouteConstants } from '../../constants/routes';

export const NavLink = ({ id, title, path, icon, isNew, isComingSoon }) => {
  const [active, setActive] = useState<number>();

  const { pathname } = useLocation();

  useEffect(() => {
    const firstTwoSegments = pathname.split('/', 3).join('/');
    if (firstTwoSegments === RouteConstants.MEMBERS) {
      setActive(2);
    } else if (firstTwoSegments === RouteConstants.OVERVIEW) {
      setActive(1);
    } else {
      setActive(1);
    }
  }, [pathname]);

  const navigate = (id: number) => {
    setActive(id);
  };

  const wrapper = (
    <>
      <i className={icon?.value}></i>
      <div className="vds-primary-navigation-side__link-text">
        {title?.value}
      </div>
      {isNew?.value && (
        <span className="vds-pill vds-pill--xsmall vds-pill--success">
          <i className="fa-solid fa-sparkles vds-pill__icon"></i>
          <span className="vds-pill__content">New</span>
        </span>
      )}
      {isComingSoon?.value && (
        <span className="vds-pill vds-pill--xsmall vds-pill--promo">
          <span className="vds-pill__content">COMING SOON</span>
        </span>
      )}
    </>
  );

  return (
    <li
      key={id}
      className={
        'vds-primary-navigation-side__item' +
        `${
          active == id + 1 ? ' vds-primary-navigation-side__item--active' : ''
        }`
      }>
      {!isComingSoon?.value ? (
        <Link
          to={path?.value}
          onClick={() => navigate(id)}
          className="vds-primary-navigation-side__link"
          aria-current="true">
          {wrapper}
        </Link>
      ) : (
        <span className="vds-primary-navigation-side__link">{wrapper}</span>
      )}
    </li>
  );
};
