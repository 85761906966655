import { ConfigProperties } from './config';

export const LocalConfig: ConfigProperties = {
  SITECORE_API_KEY: '{F226D6B3-74CC-4149-AC39-135F4F79F88F}',
  GRAPHQL_ENDPOINT: 'https://wcv4bcm.dev.local/sitecore/api/graph/edge',
  V4B_CDN_HOST: 'https://sit-v4b.cdn.vitality.co.uk',
  WSO2_SSO_HOST: 'https://sit.apis.vitality.co.uk/oauth2/oidcdiscovery',
  WSO2_CLIENT_ID: 'Bi7rfAvfzvd0SAQbfpyOhz8EzqQa',
  WSO2_CLIENT_SECRET: '',
  EMPLOYER_HUB: 'https://sit.employerhub.vitality.co.uk',
  SESSION_CHECKING_IS_OPTIONAL: true,
  ADVISER_CLIENT_ID: 'VyFhAK7aeKozFixQnioO7nqYHDoa',
  ADVISER_CLIENT_SECRET: '112uaO8L5pv4f1MKVLSeE78XD1Ua',
};
